import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NotificationService } from '../components/notifications/notifications.component';

@Directive({
  selector: '[appCopyText]',
  standalone: true
})
export class CopyTextDirective {
  @Input('appCopyText') fallbackText: string | undefined; // Получаем текст из атрибута


  constructor(private el: ElementRef, private notificationService: NotificationService, private renderer: Renderer2) {
    this.setStyle('cursor', 'pointer');
    this.setStyle('user-select', 'none');
    this.setStyle('-webkit-user-select', 'none');
    this.setStyle('-moz-user-select', 'none');
    this.setStyle('-ms-user-select', 'none');
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.setStyle('color', '#f9b125');
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.setStyle('color', '');
  }

  private setStyle(style: string, value: string) {
    this.renderer.setStyle(this.el.nativeElement, style, value);
  }

  private isIOS() {
    return navigator.userAgent.match(/ipad|iphone/i);
  }
  @HostListener('click')
  async onClick() {
    let text = this.el.nativeElement.innerText; // Получаем текст элемента

    // Если текст пустой, используем текст из атрибута
    if (!text && this.fallbackText) {
      text = this.fallbackText;
    }
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text).then(()=>this.notificationService.showNotification('Email успешно скопирован!', '', 'success-top-right', 1500));
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.style.position = 'absolute';
      textarea.style.left = '-99999999px';
      document.body.prepend(textarea);
      if(this.isIOS()) {
        var range = document.createRange();
        range.selectNodeContents(textarea);
        var selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
        textarea.setSelectionRange(0,9999);
      } else {
        textarea.select();
      }
      try {
        document.execCommand('copy');
        this.notificationService.showNotification('Email успешно скопирован!', '', 'success-top-right', 1500)
      } catch (err) {
        this.notificationService.showNotification('Email не скопирован!', '', 'error', 1500)
        //console.log(err);
      } finally {
        textarea.remove();
      }
    }
  }
}